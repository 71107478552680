.bar-chart-dv {
    min-height: 300px;
}

.info-ic{
    position: relative;
    display: inline-block;
    margin: 0px 0px 15px 7px;
    color: #333;
    font-size: 14px;
    position: relative;
    top: 3px;
}

.error.form-group input.form-control {
    border: 1px solid red!important;
}

.block-tile-title {
    margin-bottom: 5px!important;
    padding-top: 10px;
    font-size: 18px!important;
    text-transform: capitalize
}

.ui.pagination.menu .active.item{
    background-color: #0399e4;
    color: #fff;
    border-color: #0399e4;
}

.input-right-ic{
    position: absolute;
    right: 17px;
    top: 30px;
    background: #eee;
    padding: 0px 10px;
    z-index: 1;
    height: 34px;
    line-height: 34px;
    border-left: 2px solid #c5c5c5
}
span.select-fund-isin {
    color: #888;
}

.contry-phone-list .react-phone-number-input__row{
    display:flex;
}

.contry-phone-list .react-phone-number-input__row .react-phone-number-input__country {
    width: 50px;
    height: 38px;
    overflow: hidden;
    margin: 0px 0px 0px 0px;
    position: relative;
    min-width: 50px;
    border: 2px solid #c5c5c5;
    border-right: 0px;
}

.contry-phone-list .react-phone-number-input__row .react-phone-number-input__country .react-phone-number-input__country-select{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    z-index: 1;
    cursor: pointer;
    height: 100%;
    opacity: 0;
    padding: 0px 0px 0px 5px;
}

.contry-phone-list .react-phone-number-input__row .react-phone-number-input__input{
    border-radius: 0px;
    border: 2px solid #c5c5c5;
    height: 38px;
    font-size: 14px;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    border-left: 0px;
}

.contry-phone-list .react-phone-number-input__row .react-phone-number-input__icon{
    position: relative;
    height: 100%;
    width: 100%;
}

.contry-phone-list .react-phone-number-input__row .react-phone-number-input__icon:after{
    width: 0; 
    height: 0; 
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    content:"";
    border-top: 6px solid transparent;
    top: 15px;
    right: -16px;
    position: absolute;
}

h5.portfolio-info {
    position: relative;
}

h5.portfolio-info sup {
    position: absolute;
    top: 10px!important;
    left: 34px;
}
h5.portfolio-info sup.white {
    color: #fff!important;
}


header .logo-area .login-header-logo img {
    width: 130px !important;
}

.calculated-info-area-charity h3{
    border:0px !important;
}

.calculated-info-area-charity .charity-icon-table table{
    border:0px;
}


.calculated-info-area-charity .charity-icon-table table td{
    font-size: 15px;
    font-family: 'neusa_next_stdwide_regular';
    border-top:0px;
    border:0px;
}

.calculated-info-area-charity .charity-icon-table table td .checkbox input{
    width: 100%;
    height: 100%;

}

.charity-icon-table td {
    width: 33.3%
}

.contry-phone-list .react-phone-number-input__row .react-phone-number-input__country .react-phone-number-input__country-select{
    display:none;    
}
.sub-header-h3{
    line-height: 1.7;
    max-width: 580px;
    color:#535353;
}

.signup-form-text {
    font-weight: 600!important;
    font-size: 16px!important;
}

/* captcha style */
.captcha-wrapper{
    width: 100%;
    padding: 0 0 50px;
}

.captcha-wrapper > div {
    max-width: 304px;
    margin: 0 auto;
}
