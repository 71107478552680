.custom-range{
  position:relative;
  min-width: 170px;
  width:170px;
  max-width:170px;
  padding-top: 30px;
}

.custom-range .minus-btn{
  position: absolute;
  top:0px;
  background:#f1f1f1;
  border:1px solid #0399e4;
  border-radius: 3px;
  color: #000;
  left: 15px;
  width: 24px;
  height: 24px;
}

.custom-range .plus-btn{
  position: absolute;
  top:0px;
  background:#f1f1f1;
  border:1px solid #0399e4;
  color: #000;
  left: 107px;
  width: 24px;
  height: 24px;
  border-radius: 3px;
}


.custom-range .input-range__label-container{
  display:none;
}

.custom-range .input-range__track.input-range__track--active{
  background:#0399e4;
}

.custom-range .input-range__slider{
  background:#0399e4;
  border-color:#0399e4;
}

.fund-list-outer{
  position: relative;
  width:100%;
  margin:20px 0px;
}

.fund-list-outer input{
  border:1px solid #8a8a8a;
  padding:3px 10px;

}

.fund-list-outer .fund-list-dropdown{
  position: absolute;
  top: 43px;
  border: 1px solid #eee;
  -webkit-box-shadow: 0px 0px 4px rgba(0,0,0,0.2);
  box-shadow: 0px 0px 4px rgba(0,0,0,0.2);
  padding: 0px;
  left: 0px;
  min-width: 249px;
  max-height: 350px;
  overflow: auto;
}

.custom-range .input-value{
  position: absolute;
  top: 0px;
  background: #fff;
  border: 1px solid #ddd;
  color: #333;
  left: 45px;
  width: 56px;
  height: 24px;
  padding: 1px 10px;
  text-align: center;
  font-size: 12px;
}

.fund-list-outer .search-value{
  max-width: 250px;
}

.fund-list-outer .fund-list-dropdown li{
  display: block;
  position: relative;
}

.fund-list-outer .fund-list-dropdown li button{
  display: block;
  width:100%;
  background:#fff;
  border:0px;
  border-bottom:1px solid #eee;
  padding:8px 10px;
  font-size:13px;
  color:#333;
  white-space: nowrap;
  text-align: left;
  cursor: pointer;
}

.fund-list-outer .fund-list-dropdown li button:hover{
  background:#eee;
}

.remove-btn{
  border: 1px solid #dfbebe;
  background: #fff;
  border-radius: 3px;
  padding: 0;
  font-size: 12px;
  color: #e35151;
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: 135px;
  width: 24px;
  height: 24px;
}

.submit-outer{
  text-align: right;
}

.error-show-area .alert{
  margin:0px auto;
  max-width: 500px;
  text-align: center;
}
