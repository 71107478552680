@font-face {
  font-family: 'neusa_next_stdwide_bold';
  src: url('fonts/neusanextstd-widebold-webfont.woff2') format('woff2'),
       url('fonts/neusanextstd-widebold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'neusa_next_stdwide_light';
  src: url('fonts/neusanextstd-widelight-webfont.woff2') format('woff2'),
       url('fonts/neusanextstd-widelight-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'neusa_next_stdwide_medium';
  src: url('fonts/neusanextstd-widemedium-webfont.woff2') format('woff2'),
       url('fonts/neusanextstd-widemedium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'neusa_next_stdwide_regular';
  src: url('fonts/neusanextstd-wideregular-webfont.woff2') format('woff2'),
       url('fonts/neusanextstd-wideregular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'neusa_next_stdwide_thin';
  src: url('fonts/neusanextstd-widethin-webfont.woff2') format('woff2'),
       url('fonts/neusanextstd-widethin-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
