.btn-link-back {
  background: none;
  border: none;
  color: #2681a8;
  text-decoration: none;
}

.btn-link-back:hover {
  text-decoration: none;
  cursor: pointer;
}
