.none {
  margin-bottom: 0;
}

.small {
  margin-bottom: 12px;
}

.normal {
  margin-bottom: 24px;
}

.mediumLarge {
  margin-bottom: 36px;
}

.large {
  margin-bottom: 48px;
}
