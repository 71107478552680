.calender-div.start-date i{
  top: 5px;
  left: 97px;
}

.filter-top .calender-div .field.form-group .control-label{
  margin:0px 15px 0px 0px;
}

.calender-div.end_date i{
  top: 5px;
  left: 81px;
}

.filter-top .calender-div .field.form-group .form-control{
  height: 36px;
  max-width: 200px;
  font-size: 14px;
}

.filter-top .form-group.search-btn{
  margin:0px;
}

.filter-top .form-group.search-btn .btn{
  margin-left: 15px;
}
