.funds-list-outer{
  overflow: auto;
  max-height: 500px;
  width:100%;
}

.add_fund_btn{
    background: #0399e4;
    border: 0px;
    color: #fff;
    padding: 5px 13px;
    border-radius: 3px;
    font-size: 12px;
    cursor: pointer;
}

.add_fund_btn:hover{
    background: #1d7eaf;
}

.regration-page.wp-form-page .jinvest-form .ui.loading.btn.next{
    position: absolute !important;
}
